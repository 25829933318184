<template>
    <div id="setting">
        <div class="mcontainer">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">Setting</h2>
                    <p>Ubah hal-hal terkait akun Infinity anda</p>
                </div>
            </div>
            <div class="relative">
                <div class="px-1 py-3">
                    <b-tabs content-class="mt-4">
                        <b-tab title="Profile" active>
                            <div class="card">
                                <div class="col-md-12 content-form">
                                    <div class="image-uploader circle-content">
                                        <input type="file" ref="images" @change="onFileSelected($event, 'images')" required>
                                        <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                                        <img :src="images ? images : $placeholder2" alt="images" class="circle-image-150">
                                    </div>
                                    <br><br>
                                    <form @submit.prevent="">
                                        <input type="text" v-model="datauser.fullname" class="form-control" placeholder="Nama">
                                        <input type="text" v-model="datauser.no_induk" class="form-control" placeholder="NRP">
                                        <input type="text" v-model="datauser.faculity" class="form-control" placeholder="Fakultas">
                                        <input type="text" v-model="datauser.generation" class="form-control" placeholder="Angkatan">
                                        <input type="text" v-model="datauser.ttl" class="form-control" placeholder="Tempat Tanggal Lahir">
                                        <input type="text" v-model="datauser.email" class="form-control" placeholder="Email">
                                        <input type="text" v-model="datauser.phone" class="form-control" placeholder="No. Telepon">
                                        <input type="text" v-model="datauser.ig_username" class="form-control" placeholder="Username Instagram">
                                        <input type="text" v-model="datauser.address" class="form-control" placeholder="Alamat">
                                        <textarea class="form-control" v-model="datauser.about" cols="30" rows="10" placeholder="Tentang Anda"></textarea>
                                        <button class="btn btn-primary" type="submit" @click="submitUser">Simpan</button>
                                    </form>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Account Setting">
                            <div class="card">
                                <div class="col-md-12 content-form">
                                    <h3 class="title-bold">Pengaturan Privasi Data</h3><br>
                                    <div class="row">
                                        <div class="col-md-5 mb-3">
                                            <strong>Tempat Tanggal Lahir</strong><br>
                                            <p>Menampilkan Taggal Lahir anda</p>
                                        </div>
                                        <div class="col-md-7 mb-3">
                                            <div class="switches-list is-large">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" v-model="datauser.show_ttl" checked=""><span class="switch-button"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5 mb-3">
                                            <strong>Nomor NRP</strong><br>
                                            <p>Menampilkan Nomor NRP pada akun</p>
                                        </div>
                                        <div class="col-md-7 mb-3">
                                            <div class="switches-list is-large">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" v-model="datauser.show_nrp" checked=""><span class="switch-button"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <h3 class="title-bold">Pengaturan Akun</h3><br>
                                    <div class="row">
                                        <div class="col-md-5 mb-3">
                                            <strong>Instagram</strong><br>
                                            <p>Menampilkan username instagram</p>
                                        </div>
                                        <div class="col-md-7 mb-3">
                                            <div class="switches-list is-large">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" v-model="datauser.show_ig"><span class="switch-button"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5 mb-3">
                                            <strong>No telepon</strong><br>
                                            <p>Menampilkan Nomor telepon anda</p>
                                        </div>
                                        <div class="col-md-7 mb-3">
                                            <div class="switches-list is-large">
                                                <div class="switch-container">
                                                    <label class="switch"><input type="checkbox" v-model="datauser.show_telp" checked=""><span class="switch-button"></span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary" type="button" @click="submitUser">Simpan</button>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Privacy Policy">
                            <div class="card">
                                <div class="col-md-9 content-form description">
                                    <h3 class="title-bold">Introduction</h3>
                                    <p>Sed non dui aliquam, ullamcorper est non, aliquet mauris. Quisque lacus ligula, dapibus nec dignissim non, tincidunt vel quam. Etiam porttitor iaculis odio.</p>
                                    <p>Cras sagittis nunc lectus, in condimentum ligula ornare at. Etiam sagittis malesuada nisl. Duis volutpat ligula ante. Sed cursus, tortor a pellentesque pulvinar, lorem ipsum gravida elit, id posuere elit neque in est. Mauris ex justo, tincidunt at suscipit quis, pretium a mi.</p>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'Setting',
        computed: mapState('auth', { datauser: 'user' }),
        components: {
            Icon
        },
        data () {
            return {
                images: '',
                change_profile: false
            }
        },
        created () {
            this.images = this.datauser.images
        },
        methods: {
            ...mapActions('auth', ['update']),
            submitUser () {
                const { fullname, faculity, generation, ttl, email, phone, address, about, images } = this.datauser
                const igUsername = this.datauser.ig_username
                const noInduk = this.datauser.no_induk
                const showTTL = this.datauser.show_ttl
                const showIG = this.datauser.show_ig
                const showNRP = this.datauser.show_nrp
                const showTelp = this.datauser.show_telp
                const $this = this

                this.update({ data: { fullname, no_induk: noInduk, faculity, generation, ttl, email, phone, ig_username: igUsername, address, about, images, show_ttl: showTTL, show_telp: showTelp, show_nrp: showNRP, show_ig: showIG } }).then(() => {
                    this.datauser.images = this.images
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: 'Berhasil Update',
                        text: 'Informasi data diri anda berhasil disimpan.'
                    })
                })
                    .finally(() => {
                    })
            },
            onFileSelected (e, target) {
                this.datauser[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.datauser[target])
                this.change_profile = true
            }
        }
    }
</script>
